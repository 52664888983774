import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import updateProfile from '../../../actions/auth/updateProfile';

const useStyles = makeStyles(() => ({
  root: {},
}));

function ProfileDetails({ className, ...rest }: any) {
  const classes = useStyles();
  const user = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();

  const isRootUser = (user.roles || []).includes('root');

  return (
    <Formik
      initialValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        email: user.email,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255).required('First name is required'),
        lastName: Yup.string().max(255).required('Last name is required'),
        username: Yup.string().max(255).required('Username is required'),
        email: Yup.string().max(255).required('Email is required'),
      })}
      onSubmit={(values, actions) => {
        dispatch(
          updateProfile({
            firstName: values.firstName,
            lastName: values.lastName,
            username: values.username,
            email: values.email,
            actions,
          }) as any as AnyAction,
        );
      }}
    >
      {({
        errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
      }) => (
        <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest}>
          <Card>
            <CardHeader subheader="Edit your account details" title="Profile" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={typeof errors.firstName === 'string' ? errors.firstName : 'First name, aka given name'}
                    label="First name"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.firstName}
                    variant="outlined"
                    disabled={isRootUser}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={typeof errors.lastName === 'string' ? errors.lastName : 'Last name, aka family name'}
                    label="Last name"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.lastName}
                    variant="outlined"
                    disabled={isRootUser}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.username && errors.username)}
                    fullWidth
                    helperText={typeof errors.username === 'string' ? errors.username : 'Username, alphanumerical (accepts dashes)'}
                    label="Username"
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.username}
                    variant="outlined"
                    disabled={isRootUser}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={typeof errors.email === 'string' ? errors.email : 'A valid email address'}
                    label="Email Address"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                    disabled={isRootUser}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
              <Box>
                {isRootUser && (
                <Typography color="error" gutterBottom variant="caption">
                  Root user can not be edited from here
                </Typography>
                )}
              </Box>
              <Button color="primary" variant="contained" disabled={isSubmitting || isRootUser} onClick={handleSubmit as any}>
                Save details
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
}

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
