import { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box, Button, Card, CardContent, TextField, InputAdornment, SvgIcon,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { Search as SearchIcon } from 'react-feather';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { AnyAction } from 'redux';
import getFiles from '../../../actions/vault/getFiles';
import exportData from '../../../utils/exportData';
import UploadDialog from './UploadDialog';

const useStyles = makeStyles((theme: any) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 400,
    padding: theme.spacing(2, 4, 3),
  },
}));

function Toolbar({ className, ...rest }: any) {
  const classes = useStyles();
  const [uploadDialog, setUploadDialog] = useState(false);
  const [text, setText] = useState('');

  const dispatch = useDispatch();
  const files = useSelector((state: any) => state.vault.files);
  const garbage = useSelector((state: any) => state.vault.garbage);

  const csvData = [
    [
      'shield',
      'tags',
      'auth',
      'views',
      'name',
      'description',
      'extension',
      'type',
      'size',
      'duration',
      'width',
      'height',
      'timestamp',
    ],
  ];

  if (files) {
    files.forEach((e: any) => {
      // eslint-disable-next-line max-len, no-underscore-dangle
      csvData.push([
        e.shield,
        (e.tags || []).join(','),
        e.auth,
        e.views,
        e.name,
        e.description,
        e.extension,
        e.type,
        e.size,
        e.duration,
        e.width,
        e.height,
        e.timestamp,
      ]);
    });
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <CSVLink data={csvData} filename="vault.csv">
          <Button className={classes.exportButton}>Export CSV</Button>
        </CSVLink>
        <Button className={classes.exportButton} onClick={() => exportData(files)}>
          Export Data
        </Button>
        <Button color="primary" variant="contained" onClick={() => setUploadDialog(true)}>
          Upload Files
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent style={{ paddingBottom: 16 }}>
            <Box display="flex" alignItems="center">
              <Box width={500}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search files"
                  variant="outlined"
                  onChange={(e) => {
                    setText(e.target.value);
                    dispatch(getFiles(e.target.value) as any as AnyAction);
                  }}
                />
              </Box>
              <Box ml={3}>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={garbage}
                      onChange={(e) => {
                        dispatch({ type: 'garbage', value: e.target.checked });
                        dispatch(getFiles(text, e.target.checked) as any as AnyAction);
                      }}
                      name="gilad"
                    />
                  )}
                  label="Trash"
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <UploadDialog open={uploadDialog} setOpen={setUploadDialog} />
    </div>
  );
}

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
