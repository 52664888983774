import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import gravatar from 'gravatar';
import {
  Alert, Avatar, Box, Button, Divider, Drawer, Hidden, List, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import {
  BarChart as BarChartIcon,
  Code as CodeIcon,
  User as UserIcon,
  Database as DatabaseIcon,
  Users as UsersIcon,
  Key as KeyIcon,
} from 'react-feather';
import moment from 'moment';
import NavItem from './NavItem';
import config from '../../../config';
import info from '../../../version.json';

const items = [
  {
    href: '/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
    admin: false,
  },
  {
    href: '/vault',
    icon: DatabaseIcon,
    title: 'Vault',
    admin: false,
  },
  {
    href: '/users',
    icon: UsersIcon,
    title: 'Users',
    admin: true,
  },
  {
    href: '/account',
    icon: UserIcon,
    title: 'Account',
    admin: false,
  },
  {
    href: '/docs',
    icon: CodeIcon,
    title: 'API Docs',
    admin: false,
  },
  {
    href: '/keys',
    icon: KeyIcon,
    title: 'API Keys',
    admin: false,
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    marginTop: 64,
    maxHeight: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  name: {},
}));

function NavBar({ onMobileClose, openMobile }: any) {
  const classes = useStyles();
  const location = useLocation();
  const user = useSelector((state: any) => state.auth.user);

  const isAdminUser = (user.roles || []).includes('root') || (user.roles || []).includes('admin');

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={gravatar.url(user.email, { d: 'mp' })}
          to="/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {`Last login: ${moment(user.lastLogin).format('D/M/YYYY HH:mm')}`}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items
            .filter((e) => {
              if (e.admin) {
                return isAdminUser;
              }
              return true;
            })
            .map((item) => (
              <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
            ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      {config.demo && (
        <Box p={2} m={2} bgcolor="background.dark">
          <Typography align="center" gutterBottom variant="h4">
            Like what you see?
          </Typography>
          <Typography align="center" variant="body2">
            Crumble is available for sale on CodeCanyon, full source code included.
          </Typography>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              color="primary"
              component="a"
              href="https://codecanyon.net/item/crumble-enterprise-cloud-storage-scalable-file-hosting-script-nodejs-react-mongodb-gridfs/33826045"
              target="_blank"
              variant="contained"
            >
              Purchase
            </Button>
          </Box>
        </Box>
      )}
      {!config.hideVersionInfo && (
        <Box px={2} pb={2}>
          <Alert severity="success">
            v
            {info.version}
            {' '}
            (
            {info.build}
            )
          </Alert>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
